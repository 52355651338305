import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo from "../assets/logo/main-logo.png";
import facebook from "../assets/icons/facebook.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  // const navigate = useNavigate();
  return (
    <Container fluid className="bg-footer">
      <Container>
        <Row className="justify-content-between">
          <Col sm={9}>
            <div className="logo">
              <img
                src={logo}
                width="100%"
                height="100%"
                alt="Website Logo"
              />
            </div>
            <div className="copyright_text">
              Copyright © 2020 name. All Rights Reserved.{" "}
              <div>
                <span
                  style={{
                    paddingRight: " 1rem",
                    borderRight: "2px solid #ffffff",
                  }}
                  // onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy{" "}
                </span>
                <span
                  style={{
                    padding: "0 1rem",
                    borderRight: "2px solid #ffffff",
                  }}
                  // onClick={() => navigate("/terms-conditions")}
                >
                  Terms & conditions
                </span>
                <span
                  // onClick={() => navigate("/refund-policy")}
                  style={{ paddingLeft: "1rem" }}
                >
                  Cancellation & Refund Policy
                </span>
              </div>
            </div>
          </Col>
          <Col md={2} sm={3} className="align-self-center">
            <div className="d-flex justify-content-sm-between justify-content-evenly">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebook}
                  width="20"
                  height="20"
                  className="cursor-pointer"
                  alt="Facebook"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
