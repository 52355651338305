import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import IntroPage from "./components/introPage";
import NotFound from "./components/404";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IntroPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
